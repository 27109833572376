import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';


export default function BoxIndicator(props: any) {
  const {
    title,
    indicator,
    percentageLabel,
    icon
  } = props;

  let iconSymbol = null;
  if(icon){
    iconSymbol = icon === "up" ? <TrendingUpIcon /> : <TrendingDownIcon />;
  }
  return (
    <div className="boxIndicator">
      <div className="boxIndicatorContent">
        
        <div className="boxIndicatorContentInner">
          <span className="indicatorValue">{indicator}</span>
        </div>
        <h2>{title}</h2>
      </div>
    </div>
  );
}
