import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import logo from '../../assets/img/logo_2.svg';
import { Permissions } from 'Permissions';
import MenuButton from 'elements/MenuButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `170px`,
  [theme.breakpoints.up('sm')]: {
    width: `170px`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: '#090006',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
        backgroundColor: '#090006',
        color: '#ffffff',
      }
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        ...closedMixin(theme),
        backgroundColor: '#090006',
        color: '#ffffff',
      }
    }),
  }),
);

export default function MainMenu() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const role = Number(localStorage.getItem('role'));

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <div id='logo'><a href='/'><img src={logo} alt="Logo" /></a></div>
        <IconButton onClick={toggleDrawer} style={{ color: '#ffffff', backgroundColor:"#FFA2B6"}}>
          {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>

      <div className='menu_button_container'>
        {Permissions.map((permission) => {
          if (permission.role === role) {
            return permission.routes.map((route) => {
              return route.components.map((item) => (
                <Accordion key={item.path} className='menuAccordion'>
                  <MenuButton 
                    label={item.label} 
                    to={item.path} 
                    startIcon={item.icon} 
                    type={item.submenus.length > 0 ? 'summary' : null} 
                    drawerOpen={open}
                  />
                  <AccordionDetails className='menuAccordionDetails'>
                    <div className='submenu_container'>
                      {item.submenus && item.submenus.map((submenu: any) => (
                        <MenuButton key={submenu.path} label={submenu.label} to={submenu.path} startIcon={submenu.icon} />
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              ));
            });
          }
          return null;
        })}
      </div>
    </Drawer>
  );
}
