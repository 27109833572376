import axios from 'axios';
import {ProjectConfig} from 'Global';

/*=========================================================
API REQUEST
=========================================================*/
export const ApiRequest = async (params: any) => {

    const {
        url, 
        method, 
        headers, 
        body = null,
        query = null,
        setResponse,
    } = params;


    const data = (body) ? body : null;

// let config = {
//   method: 'post',
//   maxBodyLength: Infinity,
//   url: 'http://3.87.245.218:8080/api/auth/sign_in',
//   headers: { 
//     'Content-Type': 'application/json', 
//   },
//   data : data
// };

    const request_params = {
        url: ProjectConfig.api_url+url,
        method: method,
        maxBodyLength: Infinity,
        headers: {
            ...headers
        },
        params: {
            ...query
        },
        data
    }

    console.log("request_params",request_params);

    /*REQUEST
    =========================================================*/
    await axios.request(
        request_params
    )
    .then((response) => {
        console.log(`${url} response`,response.data);
        setResponse(response.data);
    })
    .catch((err) => {
        setResponse(err.response.data);
    })
    .finally(() => {

    })
    

}
