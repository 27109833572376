
import Dashboard from 'components/Dashboard/Dashboard';
import {BrowserRouter as Router , Route, Routes, Navigate} from 'react-router-dom'
import Cookies from 'universal-cookie';

import {Permissions} from './Permissions'; 
import { Fragment } from 'react';

const cookies = new Cookies();
 
const componentMap: any = {
  Dashboard,
}




export default function AppRoutes(props:any) {

  const role = Number(localStorage.getItem('role'));
  const {setLoading} = props;
  
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        {Permissions.map((permission) => {
          if (permission.role === role) {
            return permission.routes.map((route) => {
              return route.components.map((item) => {
                const Component = componentMap[item.component];
                return (
                  <Fragment key={item.path}>
                    <Route path={item.path} element={<Component setLoading={setLoading} />} />
                    {item.submenus && item.submenus.map((submenu) => {
                      const SubComponent = componentMap[submenu.component];
                      return <Route path={submenu.path} element={<SubComponent setLoading={setLoading} />} key={submenu.path} />;
                    })}
                  </Fragment>
                );
              });
            });
          }
          return null;
        })}
      </Routes>

    </>

  );
}
