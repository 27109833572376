import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ProjectConfig } from "Global";
import Box from "elements/Box";
import { Button } from "@mui/material";
import BoxIndicator from "./BoxIndicator";


export default function Indicators(props: any) {
  const {
    title,
    indicator,
    percentageLabel,
    icon
  } = props;

  return (
    <div id="dashboardIndicators">
      <BoxIndicator 
        title="Test"
        indicator="319"
        percentageLabel="+6.08%"
        icon="up"
      />

      <BoxIndicator 
        title="Test"
        indicator="319"
        percentageLabel="+6.08%"
        icon="down"
      />

      <BoxIndicator 
        title="Test"
        indicator="319"
        percentageLabel="+6.08%"
        icon="up"
      />

      
    </div>
  );
}
