import { PersonPin } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const cookies = new Cookies();

export default function MenuButton(props: any) {

  const { label, to, startIcon = null, onClickCallback, company_id = null, disabled = false, type = null, drawerOpen } = props;

  const checkActiveMenu = (href: string) => {
    if (window.location.pathname.startsWith(href)) {
      
      return true;
    }else{
      return false;
    }
  }

  const styleMenu = {
    buttons: {
      textDecoration: 'none',
      padding: '10px 20px',
      width: '100%',
      color: '#ffffff',
      textTransform: 'none',
      flexDirection: drawerOpen? 'row' : 'column',
      justifyContent: drawerOpen? 'start' : 'center',
      alignItems: drawerOpen? 'start' : 'center',
      //svg
      '& svg': {
        width: '25px',
        height: '25px',
        marginRight: drawerOpen? '10px' : '0px',
        color: checkActiveMenu(to)? '#ffffff' : '#ffffff',
      },
      //& span
      '& span': {
        marginRight: drawerOpen? '10px' : '0px',
      }
    }
  }
  


  return (
    type === 'summary'? (
    <AccordionSummary 
      expandIcon={drawerOpen? <ExpandMoreIcon sx={{color: '#ffffff'}} /> : null}
      className='menuAccordionItem'
      sx={{
        backgroundColor: checkActiveMenu(to)? '#A40A3C' : 'transparent',
        color: checkActiveMenu(to)? '#ffffff' : '#ffffff',
        paddingRight: drawerOpen? '10px !important' : '0px',
        
      }}
      
    >
      <Button
        disabled={disabled}
        component={Link}
        startIcon={startIcon}
          to={to}
        sx={styleMenu.buttons}
        onClick={onClickCallback}
        style={{
          color: checkActiveMenu(to)? '#ffffff' : '#ffffff',
          backgroundColor: checkActiveMenu(to)? '#A40A3C' : 'transparent',
          
        }}
      >
        {label}
      </Button>
    </AccordionSummary>
    )
    :
    <Button
        disabled={disabled}
        component={Link}
        startIcon={startIcon}
          to={to}
        sx={styleMenu.buttons}
        onClick={onClickCallback}
        style={{
          color: checkActiveMenu(to)? '#ffffff' : '#ffffff',
          backgroundColor: checkActiveMenu(to)? '#A40A3C' : 'transparent',
        }}
      >
        {label}
      </Button>
  );
}