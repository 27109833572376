import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';


export default function TopAccounts(props: any) {


  return (
    <div id="TopAccounts">
       <TableContainer>
              <Table aria-label="sticky table"> 
                <TableHead>
                  <TableRow>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>Empresa</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>Ventas</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>Porcentaje</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>Lorem Ipsum</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {Array.from({ length: 5 }).map((_, i) => (
                        
                        <TableRow>
                            <TableCell>Test</TableCell>
                            <TableCell>$1,200</TableCell>
                            <TableCell>80%</TableCell>
                            <TableCell>12000</TableCell>
                        </TableRow>
                ))}
                   
                    
                  
            </TableBody>
            </Table>
        </TableContainer>
    </div>
  );
}
