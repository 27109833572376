import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ProjectConfig } from "Global";
import Box from "elements/Box";
import Cookies from "universal-cookie";
import { Button } from "@mui/material";
import TopAccounts from "./components/TopAccounts";
import Indicators from "./components/indicators/Indicators";
import BoxIndicator from "./components/indicators/BoxIndicator";
import { ApiRequest } from "GlobalFunctions";
import { useSnackbar } from "notistack";

const cookies = new Cookies();

export default function Home(props: any) {
  const { setLoading } = props;

    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */


  /*=============================================
  GET DASHBOARD DATA
  =============================================*/
  const [dashboardData, setDashboardData] = useState({
    indicators: [],
    goalProgress: [],
    topAccounts: [],
    planChart: [],
  });
  

  const getDashboardData = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/dashboard/search?text_search=&per_page=20',
      method: "get",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("auth-token"),
        "csrftoken": cookies.get("X-CSRFToken"),
      }, 
      setResponse: (response: any) => {
        console.log('Dashboard Response', response);
        setLoading(false);
      }, 
    });
    
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <div id="Dashboard" className="ModuleContainer">
      <div className="ModuleWrapper">
        <div className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h1>Dashboard</h1>
          </div>

          <div className="boxContainerActions">
           
          </div>
        </div>

        <div id="DashboardIn">
          <div id="dashboardIndicators">
            {dashboardData.indicators.map((indicator: any, i: number) => (

            <BoxIndicator 
              title={indicator.title}
              indicator={indicator.value}
              percentageLabel={indicator.percentage}
              icon="up"
            />
            ))}
          </div>
          <Indicators />
          <TopAccounts />
        </div>
      </div>

    </div>
  );
}
