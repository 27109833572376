import SpeedIcon from '@mui/icons-material/Speed';

export const Permissions = [
    {
        role: 4,
        routes: [
            {
                components: [
                    {
                        path: '/dashboard', 
                        component: 'Dashboard',
                        label: 'Dashboard',
                        icon: <SpeedIcon />,
                        submenus: []
                    },
                    {
                        path: '/inventory', 
                        component: 'Dashboard',
                        label: 'Inventario',
                        icon: <SpeedIcon />,
                        submenus: []
                    },
                    {
                        path: '/transactions', 
                        component: 'Dashboard',
                        label: 'Transacciones',
                        icon: <SpeedIcon />,
                        submenus: [
                            {
                                path: '/sales',
                                component: 'Dashboard',
                                label: 'Ventas',
                            },
                            {
                                path: '/purchases',
                                component: 'Dashboard',
                                label: 'Compras',
                            },
                            {
                                path: '/orders',
                                component: 'Dashboard',
                                label: 'Cobros y pagos',
                            }
                            
                        ]
                    },
                    {
                        path: '/accounting',
                        component: 'Dashboard',
                        label: 'Contabilidad',
                        icon: <SpeedIcon />,
                        submenus: []
                    },
                    {
                        path: '/reports', 
                        component: 'Dashboard',
                        label: 'Reportes',
                        icon: <SpeedIcon />,
                        submenus: []
                    },
                    {
                        path: '/settings', 
                        component: 'Dashboard',
                        label: 'Configuracion',
                        icon: <SpeedIcon />,
                        submenus: []
                    },
                ]
            },
            
        ]
    },
]